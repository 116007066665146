@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round);

.page-not-found-container{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh;background:#46596a;width:100%}.page-not-found-container .title-container{display:flex;justify-content:center;align-items:center;margin-bottom:10px}.page-not-found-container .title-container .page-not-found-title{color:#cacaca}.page-not-found-container .title-container .page-not-found-icon{color:#cacaca;font-size:40px;margin-right:10px}

.work-in-progress-container{display:flex;align-items:center;justify-content:center;flex-direction:column;padding:8%}.work-in-progress-container .wip-image{display:flex;align-items:center;justify-content:center;height:20vh}.work-in-progress-container .wip-image img{width:100%;height:100%}.work-in-progress-container .work-in-progress-text{font-size:15px;font-style:normal;font-weight:600;color:#46596a}

header{display:flex;height:50px;align-items:center;box-sizing:border-box;justify-content:space-between;padding:0px 10px;position:fixed;width:100%;z-index:1;box-shadow:0px 4px 10px rgba(221,221,221,0.25)}header .top-left-nav{display:flex;align-items:center}header .top-left-nav .rb-logo-container{display:flex;align-items:center;padding:0px 10px}header .top-left-nav .rb-logo-container .rb-logo{height:37px;width:auto}header .top-left-nav .rb-logo-text{color:#eb367f;margin:0px;padding:20px 0px 10px 20px;font-weight:700;font-size:18px;text-decoration:none}header .header-menu{display:flex;align-items:center}header .user-info-container{display:flex;align-items:center;height:100%;padding:13px}header .user-info-container .header-menu-icon{color:#46596a;font-size:22px;width:22px}

footer{display:flex;height:25px;align-items:center;justify-content:center;z-index:1;background-color:#46596a;position:fixed;bottom:0px;width:100%}footer .footer-text{margin:0px;font-size:11px;color:#fff}

aside{z-index:1;box-shadow:5px 0px 4px -3px #e2e2e2;background-color:#f1f2f7;transition:width 0.2s ease-out}aside nav{padding-top:2px}aside nav .nav-item{display:flex;align-items:center;padding:10px 25px 10px 12px;cursor:pointer;color:#46596a;margin:5px 10px}aside nav .nav-item.active,aside nav .nav-item:hover{color:#eb367f;background:rgba(235,54,127,0.1);border-radius:5px}aside nav .nav-item.special{border-radius:4px;font-weight:600;background-color:#eb367f;color:white;margin:5px 10px}aside nav .nav-item.special:hover{background:rgba(235,54,127,0.1);color:#eb367f}aside nav .nav-item.special.active{display:flex;align-items:center;margin:none;justify-content:flex-start;width:inherit;padding:10px 25px 10px 12px;background:rgba(235,54,127,0.1);color:#eb367f;border-radius:2px}aside nav .nav-item.special.active:hover{background:rgba(235,54,127,0.1);color:#eb367f}aside nav .nav-item .nav-icon{width:20px;font-size:20px;margin-right:15px}aside nav .nav-item .nav-text{margin:0px;display:flex;font-size:14px;justify-content:left;align-items:center;transition:font-size 0.2s ease-out}aside nav .nav-item .nav-text.hidden{font-size:0px;opacity:0}aside nav .nav-item .nav-text.visible{font-size:13px;opacity:1}aside nav .nav-item-menu{display:flex;align-items:center;padding:10px 25px 10px 12px;margin:5px 10px;cursor:pointer}aside nav .nav-item-menu .nav-icon-menu{width:20px;font-size:20px;margin-right:15px;color:#46596a}aside nav .nav-item-menu .nav-text-menu{display:flex;justify-content:left;align-items:center;font-size:15px;height:25px;margin:0px;color:#46596a;transition:all 0.2s ease-out}aside nav .nav-item-menu .nav-text-menu.hidden{font-size:0px;opacity:0}aside nav .nav-item-menu .nav-text-menu.visible{font-size:13px;opacity:1}aside.light.sidenav-expanded{width:235px}aside.light.sidenav-collapsed{width:65px}

section.body{display:flex;padding-top:50px;padding-bottom:25px}section.body.not-full{min-height:unset}section.body main.content{transition:all 0.2s ease-out;padding:30px 30px 15px 30px;height:calc(100vh - 73px);box-sizing:border-box;overflow-y:auto}section.body main.content .no-apps{display:flex;align-items:center;justify-content:center;flex-direction:column;padding:8%;color:#46596a;font-size:13px}section.body main.content .no-apps img{height:20vh;width:100%}section.body main.content.main-expanded{width:calc(100% - 235px)}section.body main.content.main-collapsed{width:calc(100% - 65px)}section.body main.content.padding-none{padding:0px}section.body main.content.full{width:100%;height:auto !important}section.body main.content.power-bi-report{height:calc(100vh - 73px) !important;padding:0px !important;background:#f7f7f7;width:calc(100% - 235px)}

.breadcrumb{margin-bottom:15px;font-weight:bolder;color:#eb367f}.breadcrumb .breadcrumb-item-container .breadcrumb-item{font-size:13px;text-decoration:none !important;text-transform:capitalize;color:#eb367f}.breadcrumb .breadcrumb-item-container .breadcrumb-item.active{color:#46596a;text-transform:none;font-weight:600;text-decoration:none !important}

body{margin:0;font-family:"Hind Siliguri" !important;overflow:hidden}:focus{outline:-webkit-focus-ring-color auto 0px !important;outline:-moz-focus-ring-color auto 0px !important}.disabled-style{text-decoration:none !important}.error{color:red}button{background-color:#ea3592;border:1px solid transparent;border-radius:4px;height:30px;color:white;cursor:pointer}.material-icons-outlined,.material-icons{cursor:pointer}::-webkit-scrollbar{width:5px;height:10px}::-webkit-scrollbar-track{background:#f1f1f1}::-webkit-scrollbar-thumb{background:#b1b1b1}::-webkit-scrollbar-thumb:hover{background:#555}

